<template>
  <div
    class="container noColor"
    v-title
    :data-title="$t('i18n.lostManage')"
    id="lostManage"
  >
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.basicInfo") }}</span>
      </template>
      <div class="text item">
        <jl-form
          :columns="basicColumns"
          @onSubmit="onSubmit"
          @resetForm="goBack"
        ></jl-form>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.elevatorList") }}</span>
      </template>
      <div class="text item">
        <jl-table
          @init="getElevatorList"
          :tableData="elevatorData"
          :options="elevatorOptions"
          :columns="elevatorColumns"
          :operates="elevatorOperates"
          :total="elevatorTotal"
          @handleSelectionChange="handleSelectionChange"
          :height="300"
        >
        </jl-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "LoseUpkeepAdd",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      basicColumns: [
        {
          prop: "number",
          label: "maintenanceContractNo",
          type: "input",
          value: route.query.number,
          isUpdate: true,
          readonly: true,
        },
        {
          prop: "loseReason",
          label: "loseReason",
          type: "textarea",
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      elevatorData: [],
      elevatorTotal: 0,
      elevatorOptions: {
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: route.query.type == 2, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      elevatorColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/elevator/addEditElevator",
              query: { flag: 1, id: row.id },
            });
          },
        },
        {
          prop: "elevatorVarietyStr",
          label: "elevatorType",
          align: "center",
        },
        {
          prop: "manufacturerName",
          label: "manufacturingCompany",
          align: "center",
        },
        {
          prop: "lastCheckDate",
          label: "lastAnnualInspectionDate",
          align: "center",
        },
        {
          prop: "avgValue",
          label: "singleElevatorUpkeepCost",
          align: "center",
        },
      ],
      elevatorOperates: {
        width: 100,
        fixed: "right",
        list: [],
      },
      elevatorRenewStr: "", // 选中的电梯
    });

    const init = () => {
      if (route.query.type == 1) {
        getAllEleIds();
      }
    };

    const getAllEleIds = async () => {
      // 获取合同中所有电梯id
      let { data } = await proxy.$api.maintenance.getAllEleIds(route.query.id);
      state.elevatorRenewStr = data.join(",");
    };

    init();

    const onSubmit = async (params) => {
      if (state.elevatorRenewStr === "") {
        proxy.$defined.tip(t("i18n.selectElevators"));
      } else {
        params.preId = route.query.id;
        params.elevatorLoseStr = state.elevatorRenewStr.join(",");
        await proxy.$api.maintenance.setLose(params);
        let msg = route.query.type == 1 ? "contractLost" : "partOfContractLost";
        proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
        goBack();
      }
    };

    const goBack = () => {
      router.go(-1);
    };

    const getElevatorList = async (params) => {
      let id = route.query.id;
      const { data } = await proxy.$api.maintenance.getInAllElevator(
        id,
        params
      );
      state.elevatorData = data.records;
      state.elevatorTotal = data.total;
    };

    const handleSelectionChange = (val) => {
      let elevatorIds = [];
      val.map((ele) => {
        elevatorIds.push(ele.id);
      });
      state.elevatorRenewStr = elevatorIds;
    };

    return {
      ...toRefs(state),
      onSubmit,
      goBack,
      getElevatorList,
      handleSelectionChange,
    };
  },
};
</script>

<style lang="scss">
#lostManage {
  .form-button {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
</style>
